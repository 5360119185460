<template>
  <div>
    <!-- fixed top header -->
    <el-row>
      <el-col :span="20" :offset="2">
        <custom-header :title="selectedVariant ? selectedVariant.variant_name : ''"/>
        <el-form label-position="top" :rules="variantRules" :model="selectedVariant" ref="selectedVariant">
          <el-row :gutter="20">
            <el-col :span="8">
              <!-- product info section -->
              <div class="panel" v-if="productData">
                  <div class="panel-item">
                    <div class="panel-content">
                      <el-row :gutter="10" type="flex" align="middle">
                        <el-col :span="8">
                          <el-image
                            class="image-holder"
                            :src="productData.image[0]"
                            fit="contain"></el-image>
                        </el-col>
                        <el-col :span="16">
                          <div class="mb5">
                            <span class="mr5 text-color-primary">{{productData.name_mon}}</span>
                            <el-tag :type="productData.status === 'active' ? 'success' : 'error'" style="line-height: 24px; height: 24px">{{productData.status === 'active' ? 'Идэвхитэй' : 'Идэвхигүй'}}</el-tag>
                          </div>
                          <div>
                            <el-button type="text" @click="cellClick(productData)">Бүтээгдэхүүнрүү буцах</el-button>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
              </div>
              <!-- Variants section -->
              <div class="panel" v-if="productData && productData.variants">
                <div class="panel-item">
                  <header>Сонголтууд</header>
                </div>
                <div class="variant-list">
                  <div class="variant-item" v-for="(variant, index) in productData.variants" :key="index" :class="selectedVariant.variant_id === variant.variant_id ? 'active' : ''">
                    <el-row :gutter="10" type="flex" align="middle" @click.native="onPressVariant(variant)">
                      <el-col :span="6">
                        <el-image
                          class="image-holder"
                          :src="variant.variant_image && Array.isArray(variant.variant_image) && variant.variant_image.length !== 0 ?  variant.variant_image[0] : ''"
                          fit="contain"></el-image>
                      </el-col>
                      <el-col :span="12">
                        <span>{{variant.variant_name}}</span>
                      </el-col>
                      <el-col :span="6">
                        <span><strong>{{variant.variant_sort}}</strong></span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <el-button type="warning" @click="onPressCancelNew" v-if="isNewVariant">Болих</el-button>
              <el-button type="primary" @click="onPressNewVariant" v-show="selectedVariant.option.length >= 1" v-else>Хувилбар үүсгэх</el-button>
            </el-col>
            <el-col :span="16">
              <!-- Options section -->
              <div class="panel" v-if="selectedVariant">
                  <div class="panel-item">
                    <header>Төрөл</header>
                    <div class="panel-content">
                      <el-form-item v-for="(item, index) in selectedVariant.option" :key="index" :label="item.name">
                        <el-input v-model="item.value" key="index"></el-input>
                      </el-form-item>
                      <el-form-item label="Зураг /төрөл/" prop="variant_image">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :file-list="tempImages"
                          :auto-upload="false"
                          :on-preview="handlePictureCardPreview"
                          :on-remove="handleRemove"
                          :on-change="getFile">
                          <span>1020x1020</span>
                        </el-upload>
                        <loader :active="isUploading" />
                      </el-form-item>
                    </div>
                  </div>
              </div>
              <!-- Status section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Төлөв" prop="variant_status">
                        <el-select v-model="selectedVariant.variant_status" @change="updateVariants" class="block">
                          <el-option
                            v-for="(item, indexOption) in statusOption"
                            :key="indexOption"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Эрэмбэлэх">
                        <el-input type="number" placeholder="1 ..." v-model="selectedVariant.variant_sort" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Pricing section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Борлуулах үнэ">
                        <el-input v-model="selectedVariant.variant_sellPrice" type="number" placeholder="0₮" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                        <el-input disabled placeholder="0%" v-model="selectedVariant.brand_fee_percent">
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Тооцоо нийлэх дүн">
                        <el-input disabled placeholder="0%" v-model="selectedVariant.variant_getPrice">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Хямдарсан үнэ">
                        <el-input placeholder="Хямдралтай үнэ" v-model="selectedVariant.variant_sale_price" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                        <el-input disabled placeholder="0%" v-model="selectedVariant.brand_fee_percent">
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Хямдралтай тооцоо нийлэх дүн">
                        <el-input disabled placeholder="0" v-model="selectedVariant.variant_getPrice">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Хямдрал эхлэх хугацаа">
                        <el-date-picker disabled value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="Эхлэх огноо" v-model="selectedVariant.start_date_sale"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Хямдрал дуусах хугацаа">
                        <el-date-picker disabled value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="Дуусах огноо" v-model="selectedVariant.end_date_sale"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Хүлээгдэж буй үнэ харах">
                        <el-button type="primary" size="medium" @click="priceView()"> Үнэ харах</el-button>
                      <el-dialog title="Хүлээгдэж буй үнэ" :visible.sync="dialogTableVisiblePrice">
                         <div v-if="this.priceData === 'Хүлээгдэж буй үнэ байхгүй байна.'">
                           <span style="text-align: center; font-weight: bold; justify-content: center; display: flex;">Хүлээгдэж буй үнэ байхгүй байна.</span>
                         </div>
                         <div v-else>
                           <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Борлуулах үнэ">
                        <el-input v-model="this.priceData.sell_price" type="number" placeholder="0₮" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                        <el-input disabled placeholder="0%" v-model="this.priceData.brand_fee">
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Тооцоо нийлэх дүн">
                        <el-input disabled placeholder="0%" v-model="this.priceData.get_price">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Хямдарсан үнэ">
                        <el-input placeholder="Хямдралтай үнэ" v-model="this.priceData.discounted_price" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                        <el-input disabled placeholder="0%" v-model="this.priceData.brand_fee">
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Хямдралтай тооцоо нийлэх дүн">
                        <el-input disabled placeholder="0" v-model="this.priceData.get_discounted_price">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Хямдрал эхлэх хугацаа">
                        <el-date-picker disabled value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="Эхлэх огноо" v-model="this.priceData.start_date"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Хямдрал дуусах хугацаа">
                        <el-date-picker disabled value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="Дуусах огноо" v-model="this.priceData.end_date"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    </el-row>
                         </div>
                      </el-dialog>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <el-row type="flex" justify="space-between">
                    <header>Агуулах</header>
                    <el-button type="success" size="mini" icon="el-icon-plus" class="ml10" plain @click="addWarehouse">Агуулах нэмэх</el-button>
                  </el-row>
                  <el-row :gutter="20" v-for="(warehouse, warehouseIndex) in selectedVariant.warehouse" :key="warehouseIndex">
                    <el-col :span="11">
                      <el-form-item :label="`Агуулах ${warehouseIndex + 1}`">
                        <el-select v-model="warehouse.warehouse_id" placeholder="Агуулах">
                          <el-option
                            v-for="(item, warehouseIndex1) in warehouses"
                            :key="warehouseIndex1"
                            :label="item.name_mon"
                            :value="item.warehouse_id"
                            :disabled="selectedVariant.warehouse.some(el => el.warehouse_id === item.warehouse_id)">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item label="Тоо ширхэг">
                        <el-input v-model="warehouse.count"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2">
                      <el-button class="mt25" type="info" icon="el-icon-minus" plain circle @click="deleteWarehouse(warehouseIndex)"></el-button>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Нийт тоо ширхэг">
                        <el-input disabled type="number" v-model="selectedVariant.count">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Даймонд код">
                        <el-input v-model="selectedVariant.diamond_code" placeholder="123456...."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="SKU">
                        <el-input type="text" v-model="selectedVariant.variant_sku" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Barcode">
                        <el-input type="text" placeholder="12345678 12345698 65412" v-model="selectedVariant.variant_barcode">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                    </el-col>
                    <el-col :span="12" :offset="12">
                      <barcode  v-if="selectedVariant.variant_barcode" :value="selectedVariant.variant_barcode" :options="{ lineColor: '#0275d8'}"></barcode>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <el-row class="text-right" :gutter="20">
          <el-button type="default">Болих</el-button>
          <el-button type="success" v-if="isNewVariant" @click="addVariant('selectedVariant', 'add')">Үүсгэх</el-button>
          <el-button type="success" v-else @click="addVariant('selectedVariant', 'update')">Шинэчлэх</el-button>
        </el-row>
      </el-col>
    </el-row>
    <!-- Inventory history dialog -->
    <el-dialog title="Агуулга түүх" :visible.sync="dialogTableVisible" top="20px">
      <el-table :data="changesData">
        <el-table-column property="created_at" label="Он\Сар\Өдөр" width="150"></el-table-column>
        <el-table-column property="user_name" label="Өөрчлөгч" width="100"></el-table-column>
        <el-table-column property="warehouse_name" label="Агуулах" width="100"></el-table-column>
        <el-table-column label="Өөрчлөлт" width="100">
          <template slot-scope="scope">
            <span :class="(scope.row.old_count - scope.row.new_count) > 0 ? 'text-danger' : 'text-success'">{{((scope.row.old_count - scope.row.new_count) * -1) > 0 ? "+" + ((scope.row.old_count - scope.row.new_count) * -1) : ((scope.row.old_count - scope.row.new_count) * -1) }}</span>
          </template>
        </el-table-column>
        <el-table-column property="new_count" label="Боломжтой" width="100"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та устгах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Устгах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>

<script>
import services from '../../../helpers/services'
import helper from '../../../helpers/helper'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import Loader from '../../../components/common/loader'
import { getUserName, getSupplierId } from '../../../utils/auth'
export default {
  name: 'StockDetail',
  components: {
    CustomHeader,
    Loader
  },
  data () {
    return {
      priceData: [],
      isNewVariant: false,
      value: '',
      tag: 'svg',
      options: {
        lineColor: '#ff7069',
        fontSize: 32,
        font: 'Courier',
        width: 3,
        height: 60,
        marginBottom: 60,
        format: 'MSI',
        background: '#ccffff'
      },
      variant_barcode: '',
      productData: null,
      tempImages: [],
      selectedVariant: {
        count: 0,
        variant_sale_price: '',
        variant_get_sale_price: '',
        start_date_sale: null,
        end_date_sale: null,
        diamond_code: '',
        variant_sort: '',
        created_at: '',
        variant_id: '',
        variant_barcode: '',
        variant_getPrice: '',
        product_id: '',
        variant_name: '',
        variant_sku: '',
        variant_status: '',
        variant_image: '',
        threshold_number: '',
        sell_check: true,
        option: [],
        warehouse: [{
          count: 0,
          warehouse_name: 'default',
          rack: '',
          shelf: '',
          unit: ''
        }]
      },

      variantRules: {
        variant_sort: [
          { required: true, message: 'Эрэмбэ заавал оруулна уу?', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: 'Шинэчилж буй шалтгаанаа оруулна уу?', trigger: 'blur' }
        ],
        variant_name: [
          { required: true, message: 'Хувилбарын нэрээ оруулна уу', trigger: 'blur' }
        ],
        variant_sellPrice: [
          { required: true, message: 'Зарах үнээ оруулна уу', trigger: 'blur' }
        ],
        variant_sale_price: [
          { required: true, message: 'Хямдарсан үнээ оруулна уу', trigger: 'blur' }
        ],
        variant_getPrice: [
          { required: true, message: 'Татах үнээ оруулна уу', trigger: 'blur' }
        ],
        threshold_number: [
          { required: true, message: 'Доод үлдэгдлээ оруулна уу', trigger: 'blur' }
        ],
        variant_status: [
          { required: true, message: 'Та төлөвөө сонгоно уу?', trigger: 'blur' }
        ],
        variant_image: [
          { required: true, message: 'Та зургаа оруулна уу?', trigger: 'blur' }
        ]
      },
      backUpVariant: {
        variant_getPrice: 0,
        variant_sellPrice: 0,
        variant_status: '',
        variant_image: ''
      },
      warehouses: [],
      changesData: [],
      statusOption: [],
      currentWarehouse: [],
      dialogTableVisible: false,
      dialogTableVisiblePrice: false,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      isUploading: false,
      isLoading: false,
      dialogVisible: false,
      userName: null
    }
  },
  created () {
    if (this.$route.params.variant_id) {
      this.getVariants(this.$route.params.variant_id)
      this.getStatusOptions()
      this.getWarehousesBySupplierId()
      getUserName().then(email => {
        this.userName = email
      })
    }
  },
  methods: {
    deleteWarehouse (index) {
      this.$confirm('Та агуулах хасахдаа итгэлтэй байна уу?', 'Анхааруулга', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      }).then(async () => {
        if (this.selectedVariant.warehouse.length > 1) {
          this.selectedVariant.warehouse.splice(index, 1)
        } else {
          this.alertReporter('Анхааруулга', 'Уучлаарай та заавал агуулах сонгох ёстой. Агуулах байхгүй бол агуулах цэсрүү орж тухайн нийлүүлэгч дээр агуулах үүсгэнэ үү!', 'warning')
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Буцсан'
        })
      })
    },

    addWarehouse () {
      this.selectedVariant.warehouse.unshift({
        count: 0,
        warehouse_id: ''
      })
    },

    async getWarehousesBySupplierId () {
      this.loading = true
      const body = {
        supplier_id: await getSupplierId(),
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        this.loading = false
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
        } else {
          this.warehouses = []
        }
      })
    },

    cellClick (product) {
      this.$router.push({ name: 'updateProduct', params: { product_id: product.productId } }).catch(() => {})
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    priceView () {
      this.dialogTableVisiblePrice = true
      const variantId = this.$route.params.variant_id
      this.isLoading = true
      services.getPriceView(variantId).then((response) => {
        this.isLoading = false
        if (response.status === 'success') {
          this.priceData = response.data
        }
      })
    },
    addVariant (formName, type) {
      var variant = JSON.parse(JSON.stringify(this.selectedVariant))
      var tempImages = []
      let tempVariantName = ''
      if (variant.option.length > 0) {
        variant.option.forEach((element) => {
          tempVariantName = tempVariantName + element.value
        })
      } else {
        tempVariantName = variant.variant_name
      }
      variant.variant_name = tempVariantName
      variant.variant_getPrice = 0
      variant.variant_get_sale_price = 0
      variant.variant_sellPrice = parseInt(variant.variant_sellPrice)
      variant.variant_sale_price = parseInt(variant.variant_sale_price)
      variant.variant_sort = parseInt(variant.variant_sort)
      variant.warehouse.forEach(warehouse => {
        parseInt(warehouse.count)
      })
      this.tempImages.forEach((element) => {
        tempImages.push(element.url)
      })
      variant.variant_image = tempImages
      variant.product_id = this.productData.productId
      this.isLoading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (type === 'add') {
            services.addVariant(variant).then((response) => {
              this.isLoading = false
              if (response.status === 'success') {
                this.isNewVariant = false
                this.isLoading = false
                this.alertReporter('Амжилттай үүслээ', response.message, 'success')
                this.getVariants(this.$route.params.variant_id)
              } else {
                this.alertReporter('Алдаа гарлаа', response.message, 'error')
                this.isNewVariant = false
                this.isLoading = false
              }
            })
          } else if (type === 'update') {
            const variantUpdate = {
              variant: variant
            }
            services.updateVariant(variantUpdate).then((response) => {
              this.isLoading = false
              if (response.status === 'success') {
                this.alertReporter('Амжилттай шинэчиллээ', response.message, 'success')
                this.getVariants(this.$route.params.variant_id)
              } else {
                this.alertReporter('Алдаа гарлаа', response.message, 'error')
              }
            })
          }
        } else {
          this.isLoading = false
        }
      })
    },
    onPressCancelNew () {
      this.isNewVariant = false
      this.getVariants(this.$route.params.variant_id)
    },
    onPressNewVariant () {
      this.percent = ''
      this.difference = ''
      this.isNewVariant = true
      this.tempImages = []
      const tempOptions = []
      this.backUpVariant.option.forEach(element => {
        tempOptions.push({
          name: element.name,
          value: ''
        })
      })
      this.selectedVariant = {
        count: this.selectedVariant.count,
        variant_get_sale_price: this.selectedVariant.variant_get_sale_price,
        start_date_sale: null,
        end_date_sale: null,
        created_at: '',
        variant_id: '',
        variant_barcode: '',
        variant_getPrice: this.selectedVariant.variant_getPrice,
        variant_sellPrice: this.selectedVariant.variant_sellPrice,
        variant_sale_price: this.selectedVariant.variant_sale_price,
        variant_sort: '',
        diamond_code: '',
        product_id: '',
        variant_name: '',
        variant_sku: '',
        variant_status: 'active',
        variant_image: '',
        threshold_number: '',
        sell_check: true,
        option: tempOptions,
        warehouse: [{
          count: 0,
          warehouse_name: 'default',
          rack: '',
          shelf: '',
          unit: ''
        }]
      }
    },
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    defaultVariant (variant) {
      var newData = {
        count: variant.count ? variant.count : 0,
        brand_fee_percent: variant.brand_fee_percent,
        variant_status: variant.variant_status ? variant.variant_status : 'active',
        sell_check: variant.sell_check !== undefined ? variant.sell_check : true,
        variant_sellPrice: variant.variant_sellPrice ? variant.variant_sellPrice : 0,
        variant_getPrice: variant.variant_getPrice ? variant.variant_getPrice : 0,
        variant_barcode: variant.variant_barcode ? variant.variant_barcode : undefined,
        threshold_number: variant.threshold_number ? variant.threshold_number : 0,
        variant_sku: variant.variant_sku,
        variant_image: variant.variant_image,
        variant_name: variant.variant_name,
        variant_id: variant.variant_id,
        product_id: variant.product_id,
        variant_sort: variant.variant_sort ? variant.variant_sort : 0,
        diamond_code: variant.diamond_code ? variant.diamond_code : '',
        start_date_sale: variant.start_date_sale ? variant.start_date_sale : null,
        end_date_sale: variant.end_date_sale ? variant.end_date_sale : null,
        variant_sale_price: variant.variant_sale_price ? variant.variant_sale_price : '',
        variant_get_sale_price: variant.variant_get_sale_price ? variant.variant_get_sale_price : '',
        option: variant.option,
        warehouse: variant.warehouse
      }
      this.currentWarehouse = variant.warehouse
      this.setImages(variant.variant_image)
      return newData
    },
    getStatusOptions () {
      this.statusOption = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
    },
    onPressVariant (data) {
      const newVariant = this.defaultVariant(data)
      this.selectedVariant = newVariant
      this.backUpVariant = JSON.parse(JSON.stringify(newVariant))
    },
    updateVariants () {
      const body = {
        variant_status: this.selectedVariant.variant_status,
        variant_id: this.selectedVariant.variant_id,
        user_mail: this.userName
      }
      services.updateChangeStatus(body).then(response => {
        if (response.status === 'error') {
          this.alertReporter('Уучлаарай', response.message, 'warning')
        } else if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, 'success')
        }
      })
    },
    getVariants (variantId) {
      this.isLoading = true
      services.getOneVariant(variantId).then((response) => {
        this.isLoading = false
        if (response.status === 'success') {
          this.productData = response.data
          response.data.variants.forEach(element => {
            if (element.variant_id === variantId) {
              const newVariant = this.defaultVariant(element)
              this.selectedVariant = newVariant
              this.backUpVariant = JSON.parse(JSON.stringify(newVariant))
            }
          })
        }
      })
    },
    setImages (images) {
      var tempImages = []
      if (images && Array.isArray(images)) {
        images.forEach(element => {
          tempImages.push({
            url: element
          })
        })
      } else {
        tempImages.push({
          url: images
        })
      }
      this.tempImages = tempImages
    },
    handleRemove (file, fileList) {
      var tempId = 0
      this.tempImages.forEach((element, index) => {
        if (element.uid === file.uid) {
          tempId = index
        }
      })
      this.tempImages.splice(tempId, 1)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getFile (file, fileList) {
      handlers.checkImageSize(file, true, 1020, 1020).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              this.tempImages.push({
                url: response.url
              })
              this.selectedVariant.variant_image = response.url
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file)
        this.$message.error(error.message)
      })
    },
    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    }
  }
}
</script>
